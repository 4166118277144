

export default {
    name: 'Wish',
    components: {},
    props: {},
    data() {
        return {
            title: '',
            status: false,
            checked: false,
            visible: false,
            hasPull: true,
            query: {},
            ellipsis: {
                id: '',
            },
            params: {                // 参数
                spuIds: '',
                skuIds:''
            },
            headCompo: {             // head组件配置
                hasBack: true
            },
            listCompo: {             // list组件配置
                list: [],
                options: {
                    hasBag: false,
                    hasMore: true,
                }
            },
            page: {                   // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        process.client && this.initialSet()
    },
    mounted() {
        this.getProductList()
    },
    methods: {
        // 获取收藏
        getCollectAjax() {
            this.$api.user.getCollectList(this.page).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                !this.hasPull && (this.listCompo.list = [])
                this.listCompo.list = [...this.listCompo.list, ...(res.list || [])]
                this.visible = !!this.listCompo.list.length
            })
        },
        // 获取优惠券可用商品
        getCouponProductAjax() {
            this.$api.coupon.getCouponProduct({
                ...this.page,
                id: this.query.coupon_id
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                this.listCompo.list = res.list || []
            })
        },
        getProductList() {
            if(this.query.coupon_id) {
                this.getCouponProductAjax()
            } else {
                this.getCollectAjax()
            }
        },
        setDeleteWishAjax() { // 移除收藏商品
            this.$api.user.setDeleteCollect({
                spuIds: this.params.spuIds,
                skuIds: this.params.skuIds,
            }).then(response => {
                const res = response.result || {};
                this.getCollectAjax()
            })
        },
        // 设置状态
        handleStatus() {
            this.status = !this.status
            !this.visible && (this.status = false)
        },
        // 删除收藏
        handleDelete() {
            if(!this.checked) {
                this.$Toast(this.lang('Please Choose'))
                return
            }
            let spuIdArr = [];
            let skuIdArr = [];
            this.listCompo.list.forEach(item => {
                spuIdArr.push(item.spuId)
                skuIdArr.push(item.skuId)
            })
            this.params.spuIds = spuIdArr.join(',')
            this.params.skuIds = skuIdArr.join(',')

            this.listCompo.options.hasConcat = false
            this.setDeleteWishAjax()
        },
        // 删除按钮
        handleProductDelete(obj) {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.hasPull = false
                this.params.spuIds = obj.spuId
                this.params.skuIds = obj.skuId
                this.setDeleteWishAjax()
            }).catch(() => {})
        },
        // 显示删除按钮
        handleEllipsis(obj) {
            this.ellipsis.id = obj.id
        },
        handleShipping() {
            this.$router.push({
                name: 'Home'
            })
        },
        // emit
        emitDeleteCollect() { // 删除回调
            this.listCompo.options.hasConcat = false
            this.page.pageNum = 1
            this.getProductList()
        },
        emitBottomLoading(e, callBack) { // 触底加载
            const hasNext = this.page.pageNum < this.page.totalPage;
            if(e.isIntersecting && hasNext) {
                this.hasPull = true
                this.page.pageNum += 1
                this.listCompo.options.hasConcat = true
                this.getProductList()
            }
            callBack(!hasNext)
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Wishlist'),
                keywords: this.$translate('Wishlist'),
                description: this.$translate('Wishlist'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.title = this.$translate('Wishlist')
            this.query = this.$route.query || {}
        }
    },
}
